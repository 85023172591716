import { getPosts } from "../api/posts";
import Container from "../components/Container";
import {useState, useEffect} from "react";
import { useOutletContext } from "react-router-dom";
import Loop from "../components/Loop";

function Home() {
    document.title = 'WordPress Spiegato – Tutorial e articoli in italiano su WordPress';
    const [posts, setPosts] = useState([]);
    const [isLoaded, setIsLoaded] = useOutletContext();

    useEffect(() => {
        getPosts().then(results => {
            setPosts(results);
            setIsLoaded(true);
        }).catch(err => {
            setPosts([]);
            setIsLoaded(true);
        });
    }, []);
    return (
        <section className="home" data-loaded={isLoaded}>
            <Container>
                {posts.length > 0 && <Loop posts={posts} /> }
            </Container>
        </section>
    );
}

export default Home;