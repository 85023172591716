import { searchPosts } from "../api/posts";
import {Link, useLoaderData} from 'react-router-dom';
import Container from "../components/Container";
import '../styles/SearchResults.css';
export async function loader({ request }) {
    const url = new URL(request.url);
    const searchTerm = url.searchParams.get('search');
    const results = await searchPosts(searchTerm);
    return results;
}

function SearchResults() {
    document.title = 'Risultati della ricerca | WordPress Spiegato';
    const posts = useLoaderData();

    return (
        <div className="search-results">
            <Container>
                {Array.isArray(posts) && posts.length > 0 ?
                    <ul>
                        {posts.map((post) => (
                            <li key={post.id}>
                                <Link to={`/posts/${post.id}`}>
                                    <div dangerouslySetInnerHTML={{
                                        __html: post.title
                                    }}>
                                    </div>
                                </Link>
                            </li>
                            ))}
                    </ul>
                : <p>Nessun risultato trovato.</p>}
            </Container>
        </div>
    );
}

export default SearchResults;