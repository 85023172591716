import '../styles/App.css';
import {Outlet, useLocation} from "react-router-dom";
import MenuBar from "./MenuBar";
import Preloader from "./Preloader";
import { useState, useEffect } from "react";

function App() {
    const location = useLocation();
  const [searchVisible, setSearchVisible] = useState(false);
    const [isLoaded, setIsLoaded] = useState(true);

    useEffect(() => {
        setSearchVisible(false);
        if(!/search\/?$/g.test(location.pathname)) {
            setIsLoaded(false);
        }
    }, [location]);

  return (
    <main className="app">
        <MenuBar searchVisible={searchVisible} setSearchVisible={setSearchVisible} />
        <Outlet context={[isLoaded, setIsLoaded]} />
        {!isLoaded && <Preloader />}
    </main>
  );
}

export default App;
