import config from "../config";
import '../styles/SinglePost.css';
import {useLoaderData, useOutletContext} from 'react-router-dom';
import { getPost } from "../api/posts";
import Container from "../components/Container";
import hljs from "highlight.js";
import "highlight.js/styles/atom-one-dark.css";
import { useEffect } from "react";


export async function loader({ params }) {
    const post = await getPost(params.id);
    return { post };
}

function SinglePost() {
    const { post } = useLoaderData();
    const [isLoaded, setIsLoaded] = useOutletContext();
    document.title = `${post.title} | WordPress Spiegato`;
    useEffect(() => {
        hljs.highlightAll();
    }, []);
    return (
        <article className="single" data-loaded={isLoaded}>
            <Container>
                <header className="single-header">
                    <h1>{post.title}</h1>
                    <time>{new Date(post.date).toLocaleDateString()}</time>
                </header>
                <figure className="single-image">
                    <img onLoad={() => setIsLoaded(true)} src={`${config.imagesURL}${post.media}?size=full`} alt={post.title} />
                </figure>
                <div dangerouslySetInnerHTML={{
                    __html: post.content
                }} className="single-content">
                </div>
            </Container>

        </article>
    );
}

export default SinglePost;