import LoopPost from "./LoopPost";
import '../styles/Loop.css';

function Loop({ posts }) {
    return (
        <section className="posts">
            {posts.map((post) => (
                <LoopPost key={post.id} post={post} />
            ))}
        </section>
    );
}

export default Loop;