import React from 'react';
import ReactDOM from 'react-dom/client';
import './fonts/Poppins/Poppins-Regular.ttf';
import './fonts/Poppins/Poppins-Italic.ttf';
import './fonts/Poppins/Poppins-Bold.ttf';
import './fonts/Poppins/Poppins-Black.ttf';
import './styles/index.css';
import App from './components/App';
import Home from "./pages/Home";
import SinglePost, { loader as singlePostLoader } from "./pages/SinglePost";
import SearchResults, { loader as searchLoader } from "./pages/SearchResults";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
    {
        path: '/',
        Component: App,
        children: [
            {
                index: true,
                Component: Home
            },
            {
              path: '/posts',
              Component: Home
            },
            {
                path: '/posts/:id',
                Component: SinglePost,
                loader: singlePostLoader
            },
            {
                path: '/search',
                Component: SearchResults,
                loader: searchLoader
            }
        ]
    }
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);
