import axios from "axios";
import config from "../config";

export async function getPosts() {
    try {
        const resp = await axios.get(`${config.apiURL}posts`);
        return resp.data;
    } catch(err) {
        return [];
    }
}

export async function getPost(id) {
    try {
        const resp = await axios.get(`${config.apiURL}posts/${id}`);
        return resp.data;
    } catch(err) {
        return null;
    }
}

export async function searchPosts(search) {
    try {
        const resp = await axios.get(`${config.apiURL}search?search=${search}`);
        return resp.data;
    } catch(err) {
        return [];
    }
}