import '../styles/MenuBar.css';
import {Link} from "react-router-dom";
function MenuBar({ searchVisible, setSearchVisible }) {
    return (
        <div className="menu-bar">
            <Link to="/" className="menu-bar-item">
                <i className="fa-solid fa-house"></i>
            </Link>
            <button className="toggle-search" onClick={() => setSearchVisible(!searchVisible)}>
                <i className="fa-solid fa-magnifying-glass"></i>
            </button>
            <form method="get" action="/search" className={searchVisible ? 'visible' : ''}>
                <div>
                    <input type="text" name="search" />
                    <button type="submit">
                        <i className="fa-solid fa-magnifying-glass"></i>
                    </button>
                </div>
            </form>
        </div>
    )
}

export default MenuBar;