import config from "../config";
import {Link} from "react-router-dom";
import '../styles/LoopPost.css';
function LoopPost({ post }) {
    return (
        <article className="post">
            <header className="post-header">
                <h2>{post.title}</h2>
                <time>{new Date(post.date).toLocaleDateString()}</time>
            </header>
            <figure className="post-image">
                <img src={`${config.imagesURL}${post.media}?size=large`} alt={post.title} />
            </figure>
            <p className="post-excerpt">
                {post.excerpt.replace('<p>','').replace('</p>', '')}
            </p>
            <div className="post-more">
                <Link to={`/posts/${post.id}`} className="more-link">Leggi tutto</Link>
            </div>
        </article>
    );
}

export default LoopPost;